import '../css/layout.scss';

// Create global $ and jQuery variables
import 'jquery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).$ = (global as any).jQuery = jQuery;

// Import bootstrap JS
import 'bootstrap';

// General layout JS
import '@drenso/layout-shared';

// Hide scrollbar in side menu plugin
import 'overlayscrollbars/js/jquery.overlayScrollbars';
